<template>
  <van-overlay :show="show" class="overlay">
    <div class="overPopup">
      <div class="overPopup-text">
        请确定微信支付是否支付完成
      </div>
      <div class="overPopup-yes" @click="payyes">
        已完成支付
      </div>
      <div class="overPopup-not" @click="payyes">
        暂未支付
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'payPopup',
  data() {
    return {
      show: false,
    }
  },
  watch: {
    show() {
      this.$emit('pay-content', this.show)
    },
  },
  methods: {
    ...mapActions('user', ['getUserInfo']),

    getSetting(val) {
      this.show = val
    },
    payyes() {
      this.getUserInfo()
    },
  },
}
</script>

<style lang="less" scoped>
.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  .overPopup {
    width: 80%;
    height: auto;
    padding-left: 2vh;
    padding-right: 2vh;
    background: #ffffff;
    border-radius: 2vw;
    .overPopup-text,
    .overPopup-yes,
    .overPopup-not {
      width: 100%;
      height: 13vh;
      text-align: center;
      line-height: 13vh;
      font-size: 4vw;
      font-weight: 500;
      border-bottom: 1px solid #999999;
    }
    .overPopup-yes,
    .overPopup-not {
      height: 9vh;
      color: #ff3e2d;
      line-height: 9vh;
    }
    .overPopup-not {
      border-bottom: none;
      color: #666666;
    }
  }
}
</style>
