<template>
  <div class="app-contianer">

    <div id="app-content">
      <div class="userInfo payVipBgUrl">
        <div class="circle-avatar ">
          <img v-if="custMsg.imgUrl" :src="custMsg.imgUrl" />
          <img v-else :src="defaultUserImg" />
        </div>
        <div class="plusvip-text">
          <div class="pulsvip-title">
            <span>{{custMsg.nickName || '用户' + custMsg.custId}}</span>
            <img src="../../assets/user/notselect.png" v-if="custMsg.vipPlus === 0" class="userplus" />
            <img src="../../assets/user/alreadyselect.png" v-if="custMsg.vipPlus === 1" class="userplus" />
          </div>
          <div class="pulsvip-explain">
            {{custMsg.vipPlus === 1 ? `您已是plus会员, 到期时间: ${custMsg.vipPlusExpire}`:'您还未开通plus会员哦~'}}</div>
        </div>
      </div>

      <div class="page-item pay-box">
        <div class="title f40"><strong>开通PLUS会员</strong></div>
        <div class="pluseviptypes">
          <div class="plusvip-item" :class="key === index?'active':''" v-for="(item, index) in vipPlusList" :key="index"
            @click="changplus(index)">
            <span class="plusvip-seaon">限时优惠</span>
            <div class="plusvip-date">会员{{item.name}}</div>
            <div class="plusvip-last"><span>￥</span>{{vipPlusList[index].discountPrice || 0}}</div>
            <div class="plusvip-first">￥{{vipPlusList[index].price || 0}}</div>
          </div>
        </div>
        <div class="payType">
          <div class="title f40"><strong>支付方式</strong></div>
          <div v-if="versions === 'WeChat' && agentConfig.WECHATPAY === 'Y'" class="item"
            :class="buyPlusList.payTrxCode === 'TC003' ? 'active':''" @click="chacktext('TC003')">
            <img src="../../assets/user/wxzf.png" />
            <span class="text">微信</span>
            <van-icon name="success" />
          </div>
          <div v-if="agentConfig.ALIPAY === 'Y'" class="item" :class="buyPlusList.payTrxCode === 'TC011' ? 'active':''"
            @click="chacktext('TC011')">
            <img src="../../assets/user/zfb.png" />
            <span class="text">支付宝</span>
            <van-icon name="success" />
          </div>

          <!-- <div class="zfb-info">
          支付宝账号: {{custMsg.logonId ? custMsg.logonId:'未绑定'}}

          <div class="fr mainColor">
            {{custMsg.logonId ? '修改绑定':'去绑定'}}
          </div>
        </div> -->
        </div>
      </div>

      <div class="submitBut" @click="openMember()">立即开通</div>
    </div>

    <div v-if="show" class="zfbPay-dialong">
      <div class="zfbPay">
        <div class="zfbPay-title">
          <span>支付宝支付</span>
          <van-icon name="cross" @click="show = false" class="vanicon"></van-icon>
        </div>
        <div class="zfbPay-img">
          <div v-show="false" id="qrcode" ref="qrcode"></div>
          <img :src="zfbCodeImg" alt="">
        </div>
        <div class="zfbPay-step">
          <p>支付步骤：</p>
          <p>1.长按图片保存</p>
          <p>2.打开支付宝APP，识别该图片的二维码</p>
          <p>3.输入你的支付密码即可</p>
        </div>
      </div>
    </div>
    <payPopup v-model="showpay" ref="payPopup" style="position: absolute" @pay-content="payContent"></payPopup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getlist, getToken } from '../../api'
import wx from 'weixin-js-sdk'
import QRCode from 'qrcodejs2'
import payPopup from './compent/list/payPopup'
import { judgeTerrace } from '../../utils/utils'
import thechangePhone from '@/components/changePhone.js'
const agentCustomize = require('@/utils/agentCustomize')

const wechatAgent = Object.freeze([
  'A10012609363',
  'A10013509370'
])

export default {
  name: 'plusevip',
  components: {
    payPopup,
  },
  data() {
    return {
      defaultUserImg: agentCustomize.defaultUserImg,
      vipPlusList: [],
      key: 0,
      buyPlusList: {
        trxCode: 'XF166',
        requestType: 'MP',
        payTrxCode: '',
        vipPlusId: '',
        vipPrice: 0
      },
      show: false,
      value: 60,
      showpay: true,
      zfbCodeImg: ''
    }
  },
  computed: {
    ...mapState('user', ['custMsg', 'versions', 'agentId', 'agentConfig']),
    wechatPay() {
      let item = wechatAgent.indexOf(this.agentId)
      return item >= 0 ? true : false
    }
  },
  created() {
    this.getPrice()
  },
  methods: {
    payContent(val) {
      this.showpay = val
    },
    getPrice() {
      let params = {
        trxCode: 'MS902',
        openId: this.custMsg.openIdMp,
      }
      getlist(params).then((res) => {
        if (res.rspCode) {
          this.$toast.fail(res.rspMsg)
        } else {
          this.buyPlusList.vipPlusId = res.dataJson.vipPlusList[0].id
          this.buyPlusList.vipPrice = res.dataJson.vipPlusList[0].discountPrice
          for (var i = 0; i < res.dataJson.vipPlusList.length; i++) {
            this.vipPlusList.push(res.dataJson.vipPlusList[i])
          }
        }
      })
    },

    // 却换购买类型
    changplus(val) {
      this.key = val
      this.buyPlusList.vipPlusId = this.vipPlusList[val].id
      this.buyPlusList.vipPrice = this.vipPlusList[val].discountPrice
    },

    // 却换支付类型
    chacktext(val) {
      this.buyPlusList.payTrxCode = val
    },

    openMember() {
      // 未绑定手机号
      if (this.custMsg.phoneUser === "N") {
        thechangePhone()
        return
      }

      if (!this.buyPlusList.payTrxCode) {
        this.$toast('请选择支付方式')
        return
      }

      this.selectPay()
      return

      if (this.versions === 'H5') {
        if (this.buyPlusList.payTrxCode === 'TC003') {
          this.buyPlusList.payTrxCode = 'TC007'
          this.webPay()
        } else {
          this.selectPay()
        }
      } else {
      }
    },
    //h5微信支付
    webPay() {
      getToken(this.buyPlusList).then((res) => {
        var url = ''
        if (res.h5Url) {
          this.showpay = true
          this.$refs.payPopup.getSetting(this.showpay)
          url = res.h5Url + '&redirect_url=' + location.href
          window.location.href = url
        } else {
          this.$toast('请查看是否已开通H5支付')
        }
      })
    },
    //其他形式支付
    selectPay() {
      getToken(this.buyPlusList).then((res) => {
        if (res.rspCode === '9998') {
          this.$toast(res.rspMsg)
        } else {
          if (this.buyPlusList.payTrxCode === 'TC011') {
            this.show = true
            this.$nextTick(() => {
              let qrcode = new QRCode('qrcode', {
                width: 180, // 二维码宽度
                height: 180, // 二维码高度
                text: res.codeUrl,
              })

              // 安卓长按保存兼容
              let canvasDiv = document.getElementById('qrcode')
              let canvas = canvasDiv.querySelector('canvas')
              this.zfbCodeImg = canvas.toDataURL('image/png')
            })
          } else {
            var codeUrl = res.payInfo
            wx.chooseWXPay({
              timestamp: codeUrl.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              nonceStr: codeUrl.nonceStr, // 支付签名随机串，不长于 32 位
              package: codeUrl.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: codeUrl.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
              paySign: codeUrl.paySign, // 支付签名
              success: function (res) {
                this.$toast.success('支付成功')
              },
              fail(err) {
                this.$toast.fail(err.errMsg)
              },
            })
          }
        }

      })
    },

  },
}
</script>

<style scoped lang="less">
.payVipBgUrl {
  background-image: url('../../assets/user/payVipBg.png');
}
.userInfo {
  padding: 30px 12px 42px 12px;
  display: flex;
  align-items: center;
  background-size: 100%;
  // color: #856ff0;
  color: #fff;

  .circle-avatar {
    width: 14vw;
    height: 14vw;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 3vw;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .plusvip-text {
    flex: 1;
    .pulsvip-title {
      font-size: 4vw;
      font-weight: bold;
      .userplus {
        width: 14vw;
        margin-left: 2vw;
      }
    }
    .pulsvip-explain {
      font-size: 3.4vw;
      margin-top: 2vw;
    }
  }
}

.page-item.pay-box {
  padding: 0;
  margin: 0;
  margin-top: -12px;

  > .title {
    text-align: center;
    padding-top: 24px;
  }
}

.poster {
  width: 100%;
}
.pluseviptypes {
  display: flex;
  padding: 6vw 4vw 4vw 4vw;
  justify-content: space-between;
  box-sizing: border-box;
  border-radius: 10px;

  .plusvip-item {
    position: relative;
    padding-top: 6vw;
    padding-bottom: 2vw;
    width: 30%;
    border-radius: 10px;
    border: 2px solid #cccccc;
    box-sizing: border-box;
    text-align: center;

    .plusvip-seaon {
      position: absolute;
      top: -2px;
      left: -2px;
      color: #fff;
      background: #26273b;
      font-size: 3.2vw;
      padding: 1vw 2vw;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .plusvip-date {
      margin: 2vw 0;
      font-size: 3.6vw;
      font-weight: bold;
      color: #212438;
    }
    .plusvip-last {
      margin: 3vw 0;
      font-size: 7vw;
      font-weight: bold;
      span {
        font-size: 4vw;
      }
    }
    .plusvip-first {
      margin: 2vw 0;
      font-weight: 400;
      color: #999999;
      text-decoration: line-through;
      font-size: 3.6vw;
    }
  }

  .plusvip-item.active {
    border: 2px solid #26273b;
    box-shadow: 0px 2px 5px 1px #9c9da4;
  }
}

.payType {
  margin: 4vw;
  padding-bottom: 4vw;
  font-size: 4vw;

  > .title {
    padding-bottom: 8px;
  }

  .item {
    display: flex;
    align-items: center;
    padding: 8px 0;

    img {
      width: 7vw;
      margin-right: 2vw;
    }
    .text {
      flex: 1;
    }
    .van-icon-success {
      padding: 0.5vw;
      color: #fff;
      border: 1px solid #999;
      border-radius: 50%;
    }
  }
  .item.active {
    .van-icon-success {
      background: #26273b;
      border-color: #26273b;
    }
  }

  .zfb-info {
    font-size: 3.8vw;
    margin-top: 2vw;
  }
}

.zfbPay-dialong {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;

  .zfbPay {
    width: 80%;
    background: #fff;
    border-radius: 4vw;
    padding: 4vw;

    .zfbPay-title {
      position: relative;
      text-align: center;
      font-size: 4vw;
      font-weight: bold;
      margin-top: 2vw;
      margin-bottom: 4vw;
      .vanicon {
        float: right;
        font-size: 5vw;
      }
    }
    .zfbPay-img {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .zfbPay-step {
      font-size: 3.6vw;
      margin-top: 3vw;
    }
  }
}
.submitBut {
  margin: 12vw 4vw;
  background: #26273b;
}
</style>
